import { useStyletron } from '@thanx/uikit/theme'
import React, { ForwardedRef, ReactNode, useState } from 'react'
import { Dropdown, Nav } from 'react-bootstrap-five'
import { history } from 'store'
import { merchantTheme } from 'theme'
import { useIsDesktop } from 'utilities/responsive'
import NavButton from './NavButton'

type PropsT = {
  children: ReactNode
  buttonContents: ReactNode
  paths?: string[]
  className?: string
  rightJustifyMenu?: boolean
  disabled?: boolean
}

// if we don't use a custom NavItem, we get lots of errors because of nested anchor tags
const CustomItem = React.forwardRef(
  ({ children }, ref: ForwardedRef<HTMLDivElement>) => {
    const [css] = useStyletron()
    return (
      <div
        tabIndex={0}
        ref={ref}
        className={css({
          boxShadow: '0px 8px 20px rgba(54, 66, 78, 0.2)',
          borderRadius: '4px',
          backgroundColor: merchantTheme.colors.white,
          overflow: 'hidden',
        })}
      >
        {children}
      </div>
    )
  }
)

const MenuItem: React.FC<PropsT> = props => {
  const {
    children,
    buttonContents,
    paths = [],
    className = '',
    rightJustifyMenu,
    disabled = false,
  } = props
  const currentLocation = history.location.pathname.split('#')[0]
  const isActive = !disabled && paths.includes(currentLocation)
  const isDesktop = useIsDesktop()
  const [visible, setVisible] = useState(false)

  const handleChildClick = () => {
    setVisible(false)
    // this is to blur the Dropdown.Toggle. it doesn't support refs
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) activeElement.blur()
  }
  const toggle = () => {
    if (!disabled) setVisible(!visible)
  }
  const [css] = useStyletron()

  const fadeInClass = css({
    transform: 'scaleY(100%) rotateX(0deg) !important',
    opacity: '1 !important',
    maxHeight: '1000px !important',
  })
  const activeClass = css({
    boxShadow: 'inset 0px -10px 0px -8px ' + merchantTheme.colors.spearmint50,
  })
  const disabledClass = css({
    color: merchantTheme.colors.grey70 + ' !important',
    boxShadow: 'none !important',
    backgroundColor: 'transparent !important',
  })
  const interactiveClass = css({
    ':hover': {
      color: merchantTheme.colors.white,
      backgroundColor: merchantTheme.colors.grey100,
    },
    ':focus': {
      boxShadow: isActive
        ? 'inset 0px -10px 0px -8px ' + merchantTheme.colors.spearmint50
        : 'none',
      color: merchantTheme.colors.grey30,
      backgroundColor: merchantTheme.colors.grey90,
    },
    ':active': {
      backgroundColor: 'transparent !important',
      boxShadow: isActive
        ? 'inset 0px -10px 0px -8px ' +
          merchantTheme.colors.spearmint50 +
          '!important'
        : 'none !important',
    },
  })

  const button =
    typeof buttonContents !== 'string' ? (
      buttonContents
    ) : (
      <NavButton>{buttonContents}</NavButton>
    )

  return (
    <Nav>
      <Dropdown
        show={visible}
        onMouseEnter={() => {
          if (!visible) toggle()
        }}
        onFocus={() => {
          if (!visible) toggle()
        }}
        onMouseLeave={() => {
          if (visible) toggle()
        }}
        onBlur={() => {
          if (visible) toggle()
        }}
        className={`${className} ${css({
          perspectiveOrigin: 'center top',
          perspective: '200px',
          zIndex: 1,
        })}`}
      >
        <Dropdown.Toggle
          bsPrefix="none"
          className={`inline-block py-xs border-0 h-100 ${
            isActive ? activeClass : ''
          } ${disabled ? disabledClass : ''}
          ${disabled ? '' : interactiveClass}
          ${css({
            borderRadius: '0px',
            color: merchantTheme.colors.grey30,
            cursor: 'pointer',
            'will-change': 'opacity, transform',
            transition: 'opacity 0.2s ease, transform 0.2s ease',
            backgroundColor: merchantTheme.colors.grey90,
            paddingLeft: isDesktop ? '16px' : '8px',
            paddingRight: isDesktop ? '16px' : '8px',
          })}`}
        >
          {button}
        </Dropdown.Toggle>
        <Dropdown.Menu
          renderOnMount={true}
          onClick={() => setVisible(false)}
          bsPrefix="none"
          className={`d-flex px-m pb-m pt-xs flex-column absolute ${
            visible ? fadeInClass : ''
          } ${css({
            left: rightJustifyMenu ? 'auto' : '-24px',
            right: rightJustifyMenu ? '-24px' : 'auto',
            minWidth: '264px',
            'will-change': 'opacity, transform',
            transition: 'opacity 0.2s ease, transform 0.2s ease',
            transform: 'scaleY(0%) rotateX(-25deg)',
            opacity: 0,
            maxHeight: '0px',
            overflow: 'hidden',
            transformOrigin: 'top',
            zIndex: 2000,
          })}`}
        >
          <Dropdown.Item as={CustomItem} bsPrefix="none">
            {React.Children.map(children, child => {
              if (
                React.isValidElement(child) &&
                (child.props?.link || child.props?.onClick)
              ) {
                return React.cloneElement(child, {
                  onClick: child.props.onClick
                    ? () => {
                        child.props.onClick()
                        handleChildClick()
                      }
                    : handleChildClick,
                })
              } else {
                return child
              }
            })}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  )
}

export default MenuItem
